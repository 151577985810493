import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import {Paper, PaperProps} from '@mui/material';
import {PropsWithChildren} from "react";
import {Resizable, ResizableBox} from "react-resizable";


function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function PopupWindow() {
    const [open, setOpen] = React.useState(true);
    const [height, setHeight] = React.useState(100);
    const [width, setWidth] = React.useState(300);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (

        <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <ResizableBox width={400} height={400} draggableOpts={{grid: [25, 25]}}
                          minConstraints={[100, 100]} maxConstraints={[800, 800]}>

                <DialogTitle style={{cursor: "move"}} id="draggable-dialog-title">
                    Drag Me
                </DialogTitle>
                <DialogContent>
                    <DialogContentText height={`${height}px`} width={`${width}px`}>
                        Resize using the control in the bottom right corner.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </ResizableBox>
        </Dialog>

    );
}

